const wssPaths = Object.freeze({
  WSS_SETTINGS: "/ws-settings",
  WSS_EVENTS: "/ws-events",
  WSS_OBJECTS: "/ws-objects",
  WSS_NOTIFICATIONS: "/ws-notifications",
  WSS_PUBLIC_AGENDAS: "/ws-public-agendas",
  WSS_COMPANY: "/ws-companies",
  WSS_EVENT_TYPES: "/ws-event-types",
  WSS_EVENT_GROUPS: "/ws-event-groups",
  WSS_SUBSCRIPTION_TYPES: "/ws-subscription-types",
  WSS_SUBSCRIPTIONS: "/ws-subscriptions",
  WSS_ANNOUNCES: "/ws-announces",
});

const wssNames = Object.freeze({
  WSS_SETTINGS: "wssSettings",
  WSS_EVENTS: "wssEvents",
  WSS_OBJECTS: "wssObjects",
  WSS_NOTIFICATIONS: "wssNotifications",
  WSS_PUBLIC_AGENDAS: "wssPublicAgendas",
  WSS_COMPANY: "wssCompanies",
  WSS_EVENT_TYPES: "wssEventTypes",
  WSS_EVENT_GROUPS: "wssEventGroups",
  WSS_SUBSCRIPTION_TYPES: "wssSubscriptionTypes",
  WSS_SUBSCRIPTIONS: "wssSubscriptions",
  WSS_ANNOUNCES: "wssAnnounces",
});

export { wssPaths, wssNames };
