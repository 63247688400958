import useWS from "./websockets.js";
import { wssPaths } from "@/constants/websockets.js";

let ws;
const wssPath = wssPaths.WSS_SETTINGS;

export default function useWSSettings() {
  const { open, close } = useWS(ws, wssPath);

  return { openWSSettings: open, closeWSSettings: close };
}
