import { defineStore } from "pinia";

import { APIInvoices } from "@/api/invoices.js";

export const useInvoicesStore = defineStore("invoices", {
  state: () => {
    return {
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 5,
      order: "desc",
      planSumToPay: null,
      showPayButton: false,

      data: [],
    };
  },
  getters: {
    get: state => {
      return Object.values(state.data)
        .sort((a, b) => {
          if (state.order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    isPagerNeeded: state => {
      return (
        state.totalItems > state.displayedItems &&
        Object.values(state.data).length != state.totalItems
      );
    },
    getPlanSumToPay: state => {
      return state.planSumToPay;
    },
    getShowPayButton: state => {
      return state.showPayButton;
    },
  },
  actions: {
    fetch(payload) {
      let page = 1;
      if (Object.prototype.hasOwnProperty.call(payload, "page")) {
        page = payload.page;
      }
      return APIInvoices.getPaged(this.itemsPerPage, page)
        .then(response => {
          response.data.items.forEach(event => {
            this.insertOrUpdate(event);
          });

          this.totalItems = response.data.totalItems;
          this.displayedItems = page * this.itemsPerPage;
          this.planSumToPay = response.data.planSumToPay;
          this.showPayButton = response.data.showPayButton;
        })
        .catch(err => {
          throw new Error("Get Invoices Error", err);
        });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
