import axios from "axios";

import { Links } from "@/api/links.js";

export class ImportObjects {
  getAll() {
    return axios.get(Links.IMPORT_OBJECTS_LINK());
  }
  get(id) {
    return axios.get(Links.IMPORT_OBJECT_LINK(id));
  }
  post(item) {
    return axios.post(Links.IMPORT_OBJECTS_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.IMPORT_OBJECT_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.IMPORT_OBJECT_LINK(id));
  }
  downloadCsv() {
    return axios.get(Links.DOWNLOAD_DEMO_CSV());
  }
}

export const APIImportObjects = new ImportObjects();
