import axios from "axios";
import { jwtDecode } from "jwt-decode";

import moment from "moment";

import { useAuthStore } from "@/stores/auth.js";

import { Links } from "@/api/links.js";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

axios.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem("access-token");
    if (accessToken && config.url !== Links.REFRESH_TOKEN()) {
      // set Authorization in headers
      config.headers.Authorization = `Bearer ${accessToken}`;

      const jwtDecoded = jwtDecode(accessToken);
      const currentTime = moment().valueOf() / 1000;
      const expirationDateOneMinuteBefore = jwtDecoded.exp - 60 * 60;
      if (currentTime >= expirationDateOneMinuteBefore) {
        try {
          const authStore = useAuthStore();
          await authStore.refreshToken();
        } catch (err) {
          const errorResponse = err.response;
          if (errorResponse && errorResponse.status == 401) {
            throw new axios.Cancel(err);
          }
        }
        // set Authorization in headers equal to the NEW accessToken value (set by refresh token action)
        config.headers.Authorization = `Bearer ${localStorage.getItem("access-token")}`;
      }
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    const errorResponse = err.response;
    if (errorResponse && errorResponse.status == 401) {
      const authStore = useAuthStore();
      if (authStore.isAuthenticated) {
        authStore.deauthenticate();
      }
    }
    return Promise.reject(err);
  }
);
