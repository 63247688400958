import { defineStore } from "pinia";

import { APIImportObjects } from "@/api/import-objects.js";
import { importStatus } from "@/constants/importEventObjects.js";

export const useImportEventObjectsStore = defineStore("importEventObjects", {
  state: () => {
    return {
      data: [],
    };
  },
  getters: {
    get: state => id => state.data.find(object => object.id == id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getToDoOrInProgress(state) {
      return state.data
        .filter(event => {
          return (
            event.status == importStatus.DO_IMPORT ||
            event.status == importStatus.IMPORT_IN_PROGRESS
          );
        })
        .map(item => ({ ...item }));
    },
  },
  actions: {
    apiGet() {
      return APIImportObjects.getAll().then(res => {
        this.data = res.data;
      });
    },
    create(payload) {
      return APIImportObjects.post(payload).then(res => {
        this.data.push(res.data);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
