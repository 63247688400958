import { useCompaniesStore } from "@/stores/models-companies.js";
import { useCompanySettingsStore } from "@/stores/models-companySettings.js";
import { useEventsStore } from "@/stores/models-events.js";
import { useEventObjectsStore } from "@/stores/models-eventObjects.js";
import { useEventTypesStore } from "@/stores/models-eventTypes.js";
import { useAgendasStore } from "@/stores/models-agendas.js";
import { useImportEventObjectsStore } from "@/stores/models-importEventObjects.js";
import { useSubscriptionPlansStore } from "@/stores/models-subscriptionPlans.js";
import { useInvoicesStore } from "@/stores/models-invoices.js";
import { useEventGroupsStore } from "@/stores/models-eventGroups.js";
import { useAnnouncesStore } from "@/stores/models-announces.js";
import { useSubscriptionsStore } from "@/stores/models-subscriptions.js";
import { useSubscriptionTypesStore } from "@/stores/models-subscriptionTypes.js";

import { Links } from "@/api/links.js";

function constructWebSocketUrl(wssPath, queryParams = {}) {
  const wsUrl = new URL(`${Links.WS_LINK}${wssPath}`);

  if (localStorage.getItem("access-token")) {
    wsUrl.searchParams.append("accessToken", localStorage.getItem("access-token"));
  }

  for (const [queryParamName, queryParamValue] of Object.entries(queryParams)) {
    if (Array.isArray(queryParamValue)) {
      queryParamValue.forEach(item => {
        wsUrl.searchParams.append(queryParamName, item);
      });
    } else {
      wsUrl.searchParams.append(queryParamName, queryParamValue);
    }
  }

  return wsUrl;
}
function createWebSocket(wssPath, queryParams) {
  const wsUrl = constructWebSocketUrl(wssPath, queryParams);

  return new WebSocket(wsUrl);
}

async function upsertInStore(instance, model) {
  const Model = initModel(model);
  Model.insertOrUpdate(instance);
}

async function deleteFromStore(id, model) {
  const Model = initModel(model);
  Model.delete(id);
}

function modifyInStore(type, instance, model) {
  if (type === "create" || type === "update") {
    upsertInStore(instance, model);
  } else if (type === "delete") {
    deleteFromStore(instance.id, model);
  }
}

function initModel(model) {
  if (model == "Company") {
    const Company = useCompaniesStore();
    return Company;
  } else if (model == "CompanySetting") {
    const CompanySetting = useCompanySettingsStore();
    return CompanySetting;
  } else if (model == "Event") {
    const Event = useEventsStore();
    return Event;
  } else if (model == "EventObject") {
    const EventObject = useEventObjectsStore();
    return EventObject;
  } else if (model == "EventType") {
    const EventType = useEventTypesStore();
    return EventType;
  } else if (model == "Agenda") {
    const Agenda = useAgendasStore();
    return Agenda;
  } else if (model == "ImportEventObject") {
    const ImportEventObject = useImportEventObjectsStore();
    return ImportEventObject;
  } else if (model == "SubscriptionPlan") {
    const SubscriptionPlan = useSubscriptionPlansStore();
    return SubscriptionPlan;
  } else if (model == "Invoice") {
    const Invoice = useInvoicesStore();
    return Invoice;
  } else if (model == "EventGroup") {
    const EventGroup = useEventGroupsStore();
    return EventGroup;
  } else if (model == "Announce") {
    const Announce = useAnnouncesStore();
    return Announce;
  } else if (model == "Subscription") {
    const Subscription = useSubscriptionsStore();
    return Subscription;
  } else if (model == "SubscriptionType") {
    const SubscriptionType = useSubscriptionTypesStore();
    return SubscriptionType;
  }
}

export default {
  createWebSocket,
  modifyInStore,
};
