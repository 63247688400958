import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Invoices {
  get() {
    return axios.get(Links.INVOICE_LINK());
  }
  getPaged(itemsPerPage = 5, page = 1, order = "desc") {
    const params = {};
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    return axios.get(Links.INVOICE_LINK() + "?" + qs.stringify(params));
  }
  downloadInvoice(id) {
    const params = {};
    if (id) {
      params["id"] = id;
    }
    return axios.get(Links.INVOICE_DOWNLOAD_INVOICE_LINK() + "?" + qs.stringify(params));
  }
  getPaymentOrder() {
    return axios.get(Links.INVOICE_PAYMENT_ORDER_LINK());
  }
  getPaymentOrderStatus(orderId) {
    const params = {};
    params["orderId"] = orderId;
    return axios.get(Links.INVOICE_PAYMENT_ORDER_STATUS() + "?" + qs.stringify(params));
  }
  getNewPaymentMethodPaymentOrderStatus(orderId) {
    const params = {};
    params["orderId"] = orderId;
    return axios.get(
      Links.INVOICE_NEW_PAYMENT_METHOD_PAYMENT_ORDER_STATUS() + "?" + qs.stringify(params)
    );
  }
  getNewPaymentMethod() {
    return axios.get(Links.INVOICE_NEW_PAYMENT_METHOD_LINK());
  }
  getInvoiceProviderSeries(params) {
    return axios.get(
      Links.INVOICE_LINK() + "/validate-invoice-provider-series?" + qs.stringify(params)
    );
  }
}

export const APIInvoices = new Invoices();
