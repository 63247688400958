import { computed, watch } from "vue";

import { useAuthStore } from "@/stores/auth.js";
import { useAgendasStore } from "@/stores/models-agendas.js";

import helpers from "./helpers.js";

export default function useWS(ws, wssPath, queryParams) {
  // reauthenticate/deauthenticate on accessToken change
  const authStore = useAuthStore();
  const accessToken = computed(() => authStore.accessToken);
  watch(accessToken, newVal => {
    if (newVal) {
      open();
    } else if (!newVal) {
      close();
    }
  });

  // open connection
  function open() {
    // NOTE: Commented because websocket is used on public agenda
    // if (!accessToken.value) return;

    if (ws) {
      ws.close();
    }

    ws = helpers.createWebSocket(wssPath, queryParams);

    ws.onmessage = e => {
      console.log("ws onmessage", e);
      console.log("ws onmessage data", JSON.parse(e.data));

      const data = JSON.parse(e.data);

      if (data.type === "error") {
        if (data.message === "Your token is no longer valid") {
          const authStore = useAuthStore();
          return authStore.refreshToken();
        }
      }

      if (data.type === "message") {
        if (data.message === "An event with this event type slug was created/modified.") {
          const agendaStore = useAgendasStore();
          return agendaStore.changeAgenda();
        }
      }

      if (data.type === "create" || data.type === "update" || data.type === "delete") {
        helpers.modifyInStore(data.type, data.instance, data.model);
      }
    };

    /** ???: .. */
    // ws.onopen = e => {
    // console.log("ws onopen", e);
    // };
    ws.onerror = e => {
      console.log("ws onerror", e);
    };
    ws.onclose = e => {
      // console.log("ws onclose", e);
    };
  }

  // close connection
  function close() {
    if (ws) {
      ws.close();
    }
  }

  return { open, close };
}
