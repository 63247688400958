import { createRouter, createWebHistory } from "vue-router";

import { routes } from "./routes.js";

import { useAuthStore } from "@/stores/auth.js";
import { useCompaniesStore } from "@/stores/models-companies.js";
import { useUsersStore } from "@/stores/models-users.js";
import { useAgendasStore } from "@/stores/models-agendas.js";

import { useCompanySettingsStore } from "@/stores/models-companySettings.js";
import { companySettingsNames } from "@/constants/companySettings.js";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();
  const companySettingsStore = useCompanySettingsStore();
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({ name: "route.loginPage" });
  }

  if (to.meta.requiresUnauth && authStore.isAuthenticated) {
    return next({ path: "/", replace: true });
  }

  if (to.meta.requiresAuthContactSpace && !authStore.isAuthenticatedContactSpace) {
    return next({ name: "route.loginContactSpace", replace: true });
  } else if (to.meta.requiresUnauthContactSpace && authStore.isAuthenticatedContactSpace) {
    return next({ name: "route.contactSpace", replace: true });
  }

  if (to.meta.requiresAuth) {
    const companiesStore = useCompaniesStore();
    /* REVIEW: Maybe here is necessary to intorduce ORM?
     * https://app.clickup.com/t/2495903/FIC-423
     * I do not find necessary to make this check at beforeEach
     * Also I think the return of getCompany() is too big...
     */
    if (!companiesStore.hasCompanyData) {
      await companiesStore.getCompany();
    }

    // HACK: no agendas??????
    const agendasStore = useAgendasStore();
    if (to.name == "route.events" && agendasStore.noAgendas) {
      return next({ name: "route.myAccount", replace: true });
    }

    // ???: ??
    // TODO: We need to find a better solution
    // NOTE: Redirect router.events if company is type Subscription
    if (to.name == "route.events" && companiesStore.isTypeSubscription) {
      return next({ name: "route.subscribers.list", replace: true });
    }

    if (to.name === "route.reports") {
      if (companiesStore.isTypeServiceSubscription || companiesStore.isTypeService) {
        return next({ name: "route.reports.services", replace: true });
      } else if (companiesStore.isTypeSubscription) {
        return next({ name: "route.reports.subscriptions", replace: true });
      }
    }
    if (
      (to.name === "route.reports.services" && companiesStore.isTypeSubscription) ||
      (to.name === "route.reports.subscriptions" && companiesStore.isTypeService)
    ) {
      return next({ name: "route.reports", replace: true });
    }

    if (to.meta.requiresIsCompanyAdmin && !authStore.isCompanyAdmin) {
      return next({ path: "/", replace: true });
    }

    const usersStore = useUsersStore();
    if (
      authStore.isCompanyUser &&
      ((to.meta.requiresCompanyUserEventsPermissions && !usersStore.hasEventsRights) ||
        (to.meta.requiresCompanyUserSubscriptionsPermissions &&
          !usersStore.hasSubscriptionsRights) ||
        (to.meta.requiresCompanyUserEventObjectsPermissions && !usersStore.hasEventObjectsRights) ||
        (to.meta.requiresCompanyUserEventTypesPermissions && !usersStore.hasEventTypesRights) ||
        (to.meta.requiresCompanyUserSubscriptionTypesPermissions &&
          !usersStore.hasSubscriptionTypesRights) ||
        (to.meta.requiresCompanyUserAnnouncesPermissions && !usersStore.hasAnnouncesRights) ||
        (to.meta.requiresCompanyUserReportsPermissions &&
          !usersStore.hasEventsReportsRights &&
          !usersStore.hasSubscriptionsReportsRights) ||
        (to.meta.requiresCompanyUserEventsReportsPermissions &&
          !usersStore.hasEventsReportsRights) ||
        (to.meta.requiresCompanyUserSubscriptionsReportsPermissions &&
          !usersStore.hasSubscriptionsReportsRights))
    ) {
      // NOTE: prevent infinite redirection..
      if (to.path == "/") return next({ name: "route.myAccount", replace: true });

      // NOTE: prevent blocked subscriptions reports route..
      if (
        to.name == "route.reports.services" &&
        (companiesStore.isTypeServiceSubscription || companiesStore.isTypeSubscription) &&
        !usersStore.hasEventsReportsRights &&
        usersStore.hasSubscriptionsReportsRights
      )
        return next({ name: "route.reports.subscriptions", replace: true });

      return next({ path: "/", replace: true });
    }

    if (!!to.meta.requiresSubscriptionPlanConfirmed && !!companiesStore.subscriptionExpired) {
      return next({ name: "route.mySettings.subscription", replace: true });
    }

    // NOTE: restrict if doesn't have module active
    const hasEventTypeSetting = companySettingsStore.getValueToBoolean(
      companySettingsNames.SETTING_EVENT_TYPE
    );

    if (to.meta.requiresEventTypeModule && !hasEventTypeSetting) {
      return next({ path: "/", replace: true });
    }

    // NOTE: restrict if doesn't have module active
    const hasAgendaAvailabilitySetting = companySettingsStore.getValueToBoolean(
      companySettingsNames.SETTING_AGENDA_AVAILABILITY
    );
    if (to.meta.requiresAgendaAvailabilityModule && !hasAgendaAvailabilitySetting) {
      return next({ path: "/", replace: true });
    }

    // NOTE: restrict if doesn't have module active
    const hasFormsSetting = companySettingsStore.getValueToBoolean(
      companySettingsNames.SETTING_FORMS
    );
    if (to.meta.requiresFormsModule && !hasFormsSetting) {
      return next({ path: "/", replace: true });
    }

    if (to.name === "route.mySettings") {
      if (companiesStore.isTypeServiceSubscription || companiesStore.isTypeService) {
        return next({ name: "route.mySettings.notification", replace: true });
      } else if (companiesStore.isTypeSubscription) {
        return next({ name: "route.mySettings.category", replace: true });
      }
    }
  }

  next();
});
